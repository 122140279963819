var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Url ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item.url);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.url) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Type ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.type) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" MIME type ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.mimetype) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Size ")]), _c('v-list-item-subtitle', [_c('dt-size', {
    attrs: {
      "value": _vm.item.size
    }
  })], 1)], 1)], 1), _c('vx-card-list-meta', {
    attrs: {
      "item": _vm.item
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "References",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": _vm.$toList('page', {
        fileId: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Pages ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.pages) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('collection', {
        fileId: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Collections ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.collections) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('component-schema', {
        fileId: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Components ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.components) + " ")])], 1)], 1), _c('vx-card-list', {
    attrs: {
      "title": "Preview",
      "item": _vm.item
    }
  }, [_c('vx-img', {
    attrs: {
      "rounded": "",
      "preview": "",
      "rectangle": "",
      "src": _vm.item.url
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }