<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :item="item">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Url </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item.url)">
              {{ item.url }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Type </v-list-item-title>
            <v-list-item-subtitle> {{ item.type }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> MIME type </v-list-item-title>
            <v-list-item-subtitle> {{ item.mimetype }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Size </v-list-item-title>
            <v-list-item-subtitle>
              <dt-size :value="item.size" />
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list-meta :item="item" />
      </v-col>

      <v-col cols="12" sm="6">
        <vx-card-list title="References" :item="item" hide-edit>
          <v-list-item :to="$toList('page', { fileId: item._id })">
            <v-list-item-title> Pages </v-list-item-title>
            <v-list-item-subtitle> {{ count.pages }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('collection', { fileId: item._id })">
            <v-list-item-title> Collections </v-list-item-title>
            <v-list-item-subtitle> {{ count.collections }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('component-schema', { fileId: item._id })">
            <v-list-item-title> Components </v-list-item-title>
            <v-list-item-subtitle> {{ count.components }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Preview" :item="item">
          <vx-img rounded preview rectangle :src="item.url" />
        </vx-card-list>
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { FileService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
      count: {},
    };
  },
  created() {
    this.getFile();
    this.getCount();
  },
  methods: {
    async getFile() {
      const response = await FileService.getById(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.name);
      }
    },
    async getCount() {
      const response = await FileService.count(this.$route.params.id);
      if (response) {
        this.count = response.data;
      }
    },
  },
};
</script>
